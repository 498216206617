import React, {createContext, useState} from 'react';

export const GlobalContext = createContext("GlobalContext");

const GlobalContextProvider = ({children}) => {

    const [screenWidth, setScreenWidth] = useState(0);

    return (
        <GlobalContext.Provider
            value={{
                screenWidth,
                setScreenWidth
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
}

export default GlobalContextProvider;
