import React, {createContext, useState} from 'react';
import DesignService from "../services/DesignService";
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";

export const DesignContext = createContext("DesignContext");

const DesignContextProvider = ({children}) => {

    const [designList, setDesignList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingDesignList, setLoadingDesignList] = useState(false);
    const [designListErrorMsg, setDesignListErrorMsg] = useState('');

    const getDesignList = async (params) => {

        try {
            setLoadingDesignList(true);
            setDesignListErrorMsg('');
            const response = await DesignService.getDesignList(params);
            setDesignList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingDesignList(false);
        } catch (error) {
            setLoadingDesignList(false);
            const message = getErrorMessage(error);
            setDesignListErrorMsg(message);
            Toast('error', "Error", message);
        }

    }

    return (
        <DesignContext.Provider
            value={{
                getDesignList,
                designList,
                loadingDesignList,
                designListErrorMsg,
                totalElements
            }}
        >
            {children}
        </DesignContext.Provider>
    );
}

export default DesignContextProvider;