import axios from "axios";
import {
    CREATE_DESIGN_TYPE_URL,
    DELETE_DESIGN_TYPE_URL,
    GET_DESIGN_TYPE_BY_ID,
    GET_DESIGN_TYPE_LIST,
    UPDATE_DESIGN_TYPE_URL
} from "../helpers/Constant";

export default class DesignTypeService {

    static getDesignTypeList(params) {
        return axios.get(GET_DESIGN_TYPE_LIST, {params});
    }

    static getDesignTypeById(id) {
        return axios.get(`${GET_DESIGN_TYPE_BY_ID}/${id}`);
    }

    static addDesignType(data) {
        return axios.post(CREATE_DESIGN_TYPE_URL, data);
    }

    static updateDesignType(data, id) {
        return axios.put(`${UPDATE_DESIGN_TYPE_URL}/${id}`, data);
    }

    static deleteDesignType(id) {
        return axios.delete(`${DELETE_DESIGN_TYPE_URL}/${id}`, null);
    }
}