import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_TRANSACTION_URL,
    GET_GKASH_TRANSACTION_BY_ID,
    GET_GKASH_TRANSACTION_LIST,
    GET_TRANSACTION_BY_ID,
    GET_TRANSACTION_LIST,
    POST_TRANSACTION_URL,
    UPDATE_TRANSACTION_URL
} from "../helpers/Constant";

export default class TransactionService {

    static getTransactionList(params) {
        return axios.get(GET_TRANSACTION_LIST, { ...TransactionService.getAuthHeader(), params });
    }

    static getTransactionById(id) {
        return axios.get(`${GET_TRANSACTION_BY_ID}/${id}`, TransactionService.getAuthHeader());
    }

    static getGKashTransactionList(params) {
        return axios.get(GET_GKASH_TRANSACTION_LIST, { ...TransactionService.getAuthHeader(), params });
    }

    static getGKashTransactionById(id) {
        return axios.get(`${GET_GKASH_TRANSACTION_BY_ID}/${id}`, TransactionService.getAuthHeader());
    }

    static deleteTransaction(id) {
        return axios.delete(`${DELETE_TRANSACTION_URL}/${id}`, TransactionService.getAuthHeader());
    }

  

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return { headers: { "x-auth-token": accessToken } };
    }

}