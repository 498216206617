import React, { createContext, useState } from 'react';
import TransactionService from "../services/TransactionService";
import { Toast } from "../components/common/Toast";

export const TransactionContext = createContext("TransactionContext");

const TransactionContextProvider = ({ children }) => {

    const [transactionList, setTransactionList] = useState([]);
    const [loadingTransactionList, setLoadingTransactionList] = useState(false);
    const [TransactionListErrorMsg, setTransactionListErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getTransactionList = async (params) => {

        try {
            setLoadingTransactionList(true);
            setTransactionListErrorMsg('');
            const response = await TransactionService.getTransactionList({...params});
            setTransactionList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingTransactionList(false);
        } catch (error) {
            setLoadingTransactionList(false);
            const message = error.response ? error.response.data.message : error.message;
            setTransactionListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    const getGkashTransactionList = async (params) => {

        try {
            setLoadingTransactionList(true);
            setTransactionListErrorMsg('');
            const response = await TransactionService.getGKashTransactionList({...params});
            setTransactionList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingTransactionList(false);
        } catch (error) {
            setLoadingTransactionList(false);
            const message = error.response ? error.response.data.message : error.message;
            setTransactionListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <TransactionContext.Provider
            value={{
                getTransactionList,
                getGkashTransactionList,
                transactionList,
                loadingTransactionList,
                totalElements,
                TransactionListErrorMsg
            }}
        >
            {children}
        </TransactionContext.Provider>
    );
}

export default TransactionContextProvider;