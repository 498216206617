import axios from "axios";
import {
    CREATE_HOMEPAGE_DESIGN_URL,
    DELETE_HOMEPAGE_DESIGN_URL,
    GET_HOMEPAGE_DESIGN_BY_ID,
    GET_HOMEPAGE_DESIGN_LIST,
    UPDATE_HOMEPAGE_DESIGN_URL
} from "../helpers/Constant";

export default class HomepageDesignService {

    static getHomepageDesignList(params) {
        return axios.get(GET_HOMEPAGE_DESIGN_LIST, {params});
    }

    static addHomepageDesign(data) {
        return axios.post(CREATE_HOMEPAGE_DESIGN_URL, data);
    }

    static updateHomepageDesign(data, id) {
        return axios.put(`${UPDATE_HOMEPAGE_DESIGN_URL}/${id}`, data);
    }

    static deleteHomepageDesign(id) {
        return axios.delete(`${DELETE_HOMEPAGE_DESIGN_URL}/${id}`);
    }

    static getHomepageDesignById(id) {
        return axios.get(`${GET_HOMEPAGE_DESIGN_BY_ID}/${id}`);
    }
}
