import axios from "axios";
import {
    GET_ISSUE_BY_ID,
    GET_ISSUE_LIST,
    CHECK_ISSUE_URL
} from "../helpers/Constant";

export default class IssueService {

    static getIssuesList(params) {
        return axios.get(GET_ISSUE_LIST, {params});
    }

    static checkIssue(id, data) {
        return axios.put(`${CHECK_ISSUE_URL}/${id}`, data);
    }


    static getIssueById(id) {
        return axios.get(`${GET_ISSUE_BY_ID}/${id}`);
    }

    // static deleteIssueById(id) {
    //     return axios.delete(`${DELETE_ISSUE_BY_ID}/${id}`);
    // }

}