import React, { createContext, useState } from 'react';
import WithdrawService from "../services/WithdrawService";
import { Toast } from "../components/common/Toast";

export const WithdrawContext = createContext("WithdrawContext");

const WithdrawContextProvider = ({ children }) => {

    const [withdrawList, setWithdrawList] = useState([]);
    const [loadingWithdrawList, setLoadingWithdrawList] = useState(false);
    const [WithdrawListErrorMsg, setWithdrawListErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getWithdrawList = async (params) => {

        try {
            setLoadingWithdrawList(true);
            setWithdrawListErrorMsg('');
            const response = await WithdrawService.getWithdrawList(params);
            setWithdrawList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingWithdrawList(false);
        } catch (error) {
            setLoadingWithdrawList(false);
            const message = error.response ? error.response.data.message : error.message;
            setWithdrawListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <WithdrawContext.Provider
            value={{
                getWithdrawList,
                withdrawList,
                loadingWithdrawList,
                WithdrawListErrorMsg,
                totalElements
            }}
        >
            {children}
        </WithdrawContext.Provider>
    );
}

export default WithdrawContextProvider;