import axios from "axios";
import {CREATE_FABRIC_URL, GET_FABRIC_BY_ID, GET_FABRIC_LIST, UPDATE_FABRIC_URL} from "../helpers/Constant";

export default class FabricService {

    static getFabricList(params) {
        return axios.get(GET_FABRIC_LIST, {params});
    }

    static addFabric(data) {
        return axios.post(CREATE_FABRIC_URL, data);
    }

    static updateFabric(data, id) {
        return axios.put(`${UPDATE_FABRIC_URL}/${id}`, data);
    }

    static getFabricById(id) {
        return axios.get(`${GET_FABRIC_BY_ID}/${id}`);
    }
}