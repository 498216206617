import React from 'react';
import {Redirect} from "react-router-dom";
import {PAGE_403_PATH, PAGE_404_PATH, PAGE_500_PATH} from "../../../routes/Slugs";

const RedirectToErrorPage = (status, context) => {

    return (
        <div>
            {
                status === 404 ? <Redirect to={PAGE_404_PATH}/> :
                    status === 500 ? <Redirect to={PAGE_500_PATH}/> :
                        status === 403 ? <Redirect to={PAGE_403_PATH}/> :
                            context.errorMsg
            }
        </div>

    )
}

export default RedirectToErrorPage;
