const API_VERSION = '1.0.0';

// const { REACT_APP_BASE_URL } = process.env;
const REACT_APP_BASE_URL = 'https://api.headdos.com';
// const REACT_APP_BASE_URL = 'http://localhost:3003';
// const REACT_APP_BASE_URL = 'http://192.168.1.3:3003';

const API_URL = `${REACT_APP_BASE_URL}/api/v/${API_VERSION}`;

export const IMAGE_URL = REACT_APP_BASE_URL;

export const PUBLIC_API_URL = `${API_URL}`;
export const SECURED_API_URL = `${API_URL}`;

//auth
export const USER_LOGIN_URL = `${PUBLIC_API_URL}/auth/login`;

//user
export const GET_USER_LIST = `${SECURED_API_URL}/users`;
export const GET_USER_PROFILE = `${SECURED_API_URL}/users/profile`;
export const GET_USER_BY_ID = `${SECURED_API_URL}/users/id`;
export const CREATE_USER_URL = `${SECURED_API_URL}/users/create`;
export const UPDATE_USER_URL = `${SECURED_API_URL}/users/update`;
export const CHANGE_PASSWORD_URL = `${SECURED_API_URL}/users/change-user-password`;
export const UPDATE_USER_PROFILE_URL = `${SECURED_API_URL}/users/profile`;
export const DELETE_USER_BY_ID = `${SECURED_API_URL}/users/delete`;

export const UPDATE_WITHDRAWS_URL = `${SECURED_API_URL}/withdraws/update`;

//role
export const GET_ROLE_LIST = `${SECURED_API_URL}/roles`;
export const GET_ROLE_BY_ID = `${SECURED_API_URL}/roles/id`;
export const POST_ROLE_URL = `${SECURED_API_URL}/roles/create`;
export const UPDATE_ROLE_URL = `${SECURED_API_URL}/roles/update`;
export const DELETE_ROLE_URL = `${SECURED_API_URL}/roles/delete`;
export const ALL_PERMISSION_URL = `${SECURED_API_URL}/roles/all-permissions`;


//image upload
export const UPLOAD_PROFILE_PICTURE = `${SECURED_API_URL}/upload/profile-picture`;
export const UPLOAD_NID_BACK_PICTURE = `${SECURED_API_URL}/nid-back-pic/upload`;
export const UPLOAD_NID_FRONT_PICTURE = `${SECURED_API_URL}/nid-front-pic/upload`;
export const UPLOAD_ADMIN_PICTURES = `${SECURED_API_URL}/upload/admin-portal-image`;
export const UPLOAD_SERVICE_IMAGE = `${SECURED_API_URL}/upload/service-icon`;
export const UPLOAD_POPUP_BANNER_PICTURES = `${SECURED_API_URL}/upload/popup-banner-image`;

//design
export const GET_DESIGN_LIST = `${SECURED_API_URL}/designs`;
export const GET_DESIGN_BY_ID = `${SECURED_API_URL}/designs/id`;
export const CREATE_DESIGN_URL = `${SECURED_API_URL}/designs/create`;
export const UPDATE_DESIGN_URL = `${SECURED_API_URL}/designs/update`;
export const DELETE_DESIGN_URL = `${SECURED_API_URL}/designs/delete`;

export const UPLOAD_DESIGN_PICTURE = `${SECURED_API_URL}/design-pic/upload`;

//design type
export const GET_DESIGN_TYPE_LIST = `${SECURED_API_URL}/design-types`;
export const GET_DESIGN_TYPE_BY_ID = `${SECURED_API_URL}/design-types/id`;
export const CREATE_DESIGN_TYPE_URL = `${SECURED_API_URL}/design-types/create`;
export const UPDATE_DESIGN_TYPE_URL = `${SECURED_API_URL}/design-types/update`;
export const DELETE_DESIGN_TYPE_URL = `${SECURED_API_URL}/design-types/delete`;

//design tag
export const GET_DESIGN_TAG_LIST = `${SECURED_API_URL}/tags`;
export const GET_DESIGN_TAG_BY_ID = `${SECURED_API_URL}/tags/id`;
export const CREATE_DESIGN_TAG_URL = `${SECURED_API_URL}/tags/create`;
export const UPDATE_DESIGN_TAG_URL = `${SECURED_API_URL}/tags/update`;
export const DELETE_DESIGN_TAG_URL = `${SECURED_API_URL}/tags/delete`;

//fabric
export const GET_FABRIC_LIST = `${SECURED_API_URL}/fabrics`;
export const GET_FABRIC_BY_ID = `${SECURED_API_URL}/fabrics/id`;
export const CREATE_FABRIC_URL = `${SECURED_API_URL}/fabrics/create`;
export const UPDATE_FABRIC_URL = `${SECURED_API_URL}/fabrics/update`;

//homepage-design
export const GET_HOMEPAGE_DESIGN_LIST = `${SECURED_API_URL}/homepage-designs`;
export const GET_HOMEPAGE_DESIGN_BY_ID = `${SECURED_API_URL}/homepage-designs/id`;
export const CREATE_HOMEPAGE_DESIGN_URL = `${SECURED_API_URL}/homepage-designs/create`;
export const UPDATE_HOMEPAGE_DESIGN_URL = `${SECURED_API_URL}/homepage-designs/update`;
export const DELETE_HOMEPAGE_DESIGN_URL = `${SECURED_API_URL}/homepage-designs/delete`;

export const UPLOAD_HOMEPAGE_DESIGN_PICTURE = `${SECURED_API_URL}/homepage-pic/upload`;

export const UPLOAD_FABRIC_PICTURE = `${SECURED_API_URL}/fabric-pic/upload`;

//order
export const GET_ORDER_LIST = `${SECURED_API_URL}/orders`;
export const GET_ORDER_BY_ID = `${SECURED_API_URL}/orders/id`;
export const CREATE_ORDER_URL = `${SECURED_API_URL}/orders/create`;
export const UPDATE_ORDER_STATUS_URL = `${SECURED_API_URL}/orders/status-update`;

//order state
export const GET_ORDER_STATE_BY_ORDER_ID = `${SECURED_API_URL}/orders/state`;

//promo
export const GET_PROMO_LIST = `${SECURED_API_URL}/promos`;
export const GET_PROMO_BY_ID = `${SECURED_API_URL}/promos/id`;
export const CREATE_PROMO_URL = `${SECURED_API_URL}/promos/create`;
export const UPDATE_PROMO_URL = `${SECURED_API_URL}/promos/update`;

export const UPLOAD_COMPANY_IMAGE = '';

//global-config
export const GET_GLOBAL_CONFIG_LIST = `${SECURED_API_URL}/global-configs`;
export const GET_GLOBAL_CONFIG_BY_ID = `${SECURED_API_URL}/global-configs/id`;
export const POST_GLOBAL_CONFIG_URL = `${SECURED_API_URL}/global-configs/create`;
export const UPDATE_GLOBAL_CONFIG_URL = `${SECURED_API_URL}/global-configs/update`;
export const DELETE_GLOBAL_CONFIG_URL = `${SECURED_API_URL}/global-configs/delete`;

//city
export const GET_CITY_LIST = `${SECURED_API_URL}/cities`;
export const GET_CITY_BY_ID = `${SECURED_API_URL}/cities/id`;
export const POST_CITY_URL = `${SECURED_API_URL}/cities/create`;
export const UPDATE_CITY_URL = `${SECURED_API_URL}/cities/update`;
export const DELETE_CITY_URL = `${SECURED_API_URL}/cities/delete`;

//booking
export const GET_ALL_BOOKINGS = `${SECURED_API_URL}/bookings`;
export const GET_BOOKING_BY_ID = `${SECURED_API_URL}/bookings/id`;

//country-code
export const GET_COUNTRY_CODE_LIST = `${SECURED_API_URL}/country-codes`;
export const GET_COUNTRY_CODE_BY_ID = `${SECURED_API_URL}/country-codes/id`;
export const POST_COUNTRY_CODE_URL = `${SECURED_API_URL}/country-codes/create`;
export const UPDATE_COUNTRY_CODE_URL = `${SECURED_API_URL}/country-codes/update`;
export const DELETE_COUNTRY_CODE_URL = `${SECURED_API_URL}/country-codes/delete`;

//service
export const GET_SERVICE_LIST = `${SECURED_API_URL}/services`;
export const GET_PARENT_SERVICE_LIST = `${SECURED_API_URL}/services/parents`;
export const GET_SERVICE_BY_ID = `${SECURED_API_URL}/services/id`;
export const POST_SERVICE_URL = `${SECURED_API_URL}/services/create`;
export const UPDATE_SERVICE_URL = `${SECURED_API_URL}/services/update`;
export const VERIFY_SERVICE_URL = `${SECURED_API_URL}/services/verify`;
export const DELETE_SERVICE_URL = `${SECURED_API_URL}/services/delete`;
export const MAKE_HOT_SERVICE_URL = `${SECURED_API_URL}/services/hot`;

//employee-salary
export const GET_EMPLOYEE_SALARY_LIST = `${SECURED_API_URL}/employee-salaries`;
export const GET_EMPLOYEE_SALARY_BY_ID = `${SECURED_API_URL}/employee-salaries/id`;
export const POST_EMPLOYEE_SALARY_URL = `${SECURED_API_URL}/employee-salaries/create`;
export const UPDATE_EMPLOYEE_SALARY_URL = `${SECURED_API_URL}/employee-salaries/update`;
export const DELETE_EMPLOYEE_SALARY_URL = `${SECURED_API_URL}/employee-salaries/delete`;


//static-page
export const GET_STATIC_PAGE_LIST = `${SECURED_API_URL}/static-pages`;
export const GET_STATIC_PAGE_BY_ID = `${SECURED_API_URL}/static-pages/id`;
export const GET_STATIC_PAGE_BY_TITLE = `${SECURED_API_URL}/static-pages/title`;
export const POST_STATIC_PAGE_URL = `${SECURED_API_URL}/static-pages/create`;
export const UPDATE_STATIC_PAGE_URL = `${SECURED_API_URL}/static-pages/update`;
export const DELETE_STATIC_PAGE_URL = `${SECURED_API_URL}/static-pages/delete`;

//dashboard
export const GET_BOOKING_GRAPH_BY_DAY = `${SECURED_API_URL}/dashboard-data/booking-graph`;
export const GET_PROVIDER_REG_GRAPH_BY_DAY = `${SECURED_API_URL}/dashboard-data/provider-reg-graph`;
export const GET_CUSTOMER_REG_GRAPH_BY_DAY = `${SECURED_API_URL}/dashboard-data/customer-reg-graph`;
export const GET_ADMIN_DASHBOARD_INFO = `${SECURED_API_URL}/dashboard-data/admin-dashboard-info`;

//issue
export const GET_ISSUE_LIST = `${SECURED_API_URL}/issues`;
export const GET_ISSUE_BY_ID = `${SECURED_API_URL}/issues/id`;
export const CHECK_ISSUE_URL = `${SECURED_API_URL}/issues/check`;

//transaction
export const GET_TRANSACTION_LIST = `${SECURED_API_URL}/transactions`;
export const GET_TRANSACTION_BY_ID = `${SECURED_API_URL}/transactions/id`;
export const GET_GKASH_TRANSACTION_LIST = `${SECURED_API_URL}/transactions/gkash`;
export const GET_GKASH_TRANSACTION_BY_ID = `${SECURED_API_URL}/transactions/gkash/id`;
export const POST_TRANSACTION_URL = `${SECURED_API_URL}/transactions/create`;
export const UPDATE_TRANSACTION_URL = `${SECURED_API_URL}/transactions/update`;
export const DELETE_TRANSACTION_URL = `${SECURED_API_URL}/transactions/delete`;

//popup banners
export const GET_POPUP_BANNERS_LIST = `${SECURED_API_URL}/popup-banners`;
export const GET_POPUP_BANNERS_BY_ID = `${SECURED_API_URL}/popup-banners/id`;
export const POST_POPUP_BANNERS_URL = `${SECURED_API_URL}/popup-banners/create`;
export const UPDATE_POPUP_BANNERS_URL = `${SECURED_API_URL}/popup-banners/update`;
export const DELETE_POPUP_BANNERS_URL = `${SECURED_API_URL}/popup-banners/delete`;

//notification
export const SEND_NOTIFICATION = `${SECURED_API_URL}/users/send-notification-to-users`;

//withdraw
export const GET_ALL_WITHDRAWS = `${SECURED_API_URL}/withdraws`;
export const GET_WITHDRAW_BY_ID = `${SECURED_API_URL}/withdraws/id`;

// Token & Profile
export const ACCESS_TOKEN = 'head-do-token';
export const PROFILE = "head-do-profile";

// Currency
export const BD_TK_SIGN = "৳ ";

// Gender

export const GENDER_COLOR = {
    MALE: "blue",
    FEMALE: "green",
    OTHERS: "purple"
}

//back button color
export const BACK_BUTTON_COLOR = "#3390FF";

//color
export const RED = "#dc3545";
export const GREEN = "#28a745";
export const YELLOW = "#ffc107";

//order state color
export const ORDER_STATUS_COLOR = {
    INITIALIZED: "#3390FF",
    RAW_FABRIC_PROCESSING: "#ffc107",
    READY_FOR_PRINTING: "#5f9ea0",
    PRINTING_FINISHED: "#7fffd4",
    DELIVERED: "#28a745",
    CANCELLED: "#dc3545"
}
export const STATUS_COLOR = {
    'Application successful': "blue",
    'Application Rejected': 'red',
    'Documents checked': 'green'
}


export const INITIALIZED = "INITIALIZED";
export const RAW_FABRIC_PROCESSING = "RAW_FABRIC_PROCESSING";
export const READY_FOR_PRINTING = "READY_FOR_PRINTING";
export const PRINTING_FINISHED = "PRINTING_FINISHED";
export const DELIVERED = "DELIVERED";
export const CANCELLED = "CANCELLED";

export const ORDER_STATUS_SEQUENCE = {
    INITIALIZED: "RAW_FABRIC_PROCESSING",
    RAW_FABRIC_PROCESSING: "READY_FOR_PRINTING",
    READY_FOR_PRINTING: "PRINTING_FINISHED",
    PRINTING_FINISHED: "DELIVERED",
}
