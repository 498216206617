import {notification} from "antd";

export const Toast = (type, title, desc) => {

    notification[type]({
        message: title,
        description: desc,
        placement: 'bottomRight'
    });
};
