import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_EMPLOYEE_SALARY_URL,
    GET_EMPLOYEE_SALARY_BY_ID,
    GET_EMPLOYEE_SALARY_LIST,
    POST_EMPLOYEE_SALARY_URL,
    UPDATE_EMPLOYEE_SALARY_URL
} from "../helpers/Constant";

export default class EmployeeSalaryService {

    static getEmployeeSalaryList(params) {
        return axios.get(GET_EMPLOYEE_SALARY_LIST, { ...EmployeeSalaryService.getAuthHeader(), params });
    }

    static addEmployeeSalary(data) {
        return axios.post(POST_EMPLOYEE_SALARY_URL, data, EmployeeSalaryService.getAuthHeader());
    }

    static getEmployeeSalaryById(id) {
        return axios.get(`${GET_EMPLOYEE_SALARY_BY_ID}/${id}`, EmployeeSalaryService.getAuthHeader());
    }

    static deleteEmployeeSalary(id) {
        return axios.delete(`${DELETE_EMPLOYEE_SALARY_URL}/${id}`, EmployeeSalaryService.getAuthHeader());
    }

    static editEmployeeSalary(id, data) {
        return axios.put(`${UPDATE_EMPLOYEE_SALARY_URL}/${id}`, data, EmployeeSalaryService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return { headers: { "x-auth-token": accessToken } };
    }

}