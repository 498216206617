import axios from "axios";
import {
    CREATE_ORDER_URL,
    GET_ORDER_BY_ID,
    GET_ORDER_LIST,
    GET_ORDER_STATE_BY_ORDER_ID,
    UPDATE_ORDER_STATUS_URL
} from "../helpers/Constant";

export default class OrderService {

    static getOrderList(params) {
        return axios.get(GET_ORDER_LIST, {params});
    }

    static addOrder(data) {
        return axios.post(CREATE_ORDER_URL, data);
    }

    static updateOrderStatus(id, orderStatus) {
        return axios.patch(`${UPDATE_ORDER_STATUS_URL}/${id}`, null, {params: {orderStatus}});
    }

    static getOrderById(id) {
        return axios.get(`${GET_ORDER_BY_ID}/${id}`);
    }

    static getOrderStateByOrderId(id) {
        return axios.get(`${GET_ORDER_STATE_BY_ORDER_ID}/${id}`);
    }
}
