import React, {createContext, useState} from 'react';
import PromoService from "../services/PromoService";
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";

export const PromoContext = createContext("PromoContext");

const PromoContextProvider = ({children}) => {

    const [promoList, setPromoList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingPromoList, setLoadingPromoList] = useState(false);
    const [promoListErrorMsg, setPromoListErrorMsg] = useState('');

    const getPromoList = async (params) => {

        try {
            setLoadingPromoList(true);
            setPromoListErrorMsg('');
            const response = await PromoService.getPromoList(params);
            setPromoList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingPromoList(false);
        } catch (error) {
            setLoadingPromoList(false);
            const message = getErrorMessage(error);
            setPromoListErrorMsg(message);
            Toast('error', "Error", message);
        }

    }

    return (
        <PromoContext.Provider
            value={{
                getPromoList,
                promoList,
                loadingPromoList,
                promoListErrorMsg,
                totalElements
            }}
        >
            {children}
        </PromoContext.Provider>
    );
}

export default PromoContextProvider;
