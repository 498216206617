import React, { createContext, useState } from 'react';
import BookingService from "../services/BookingService";
import { Toast } from "../components/common/Toast";

export const BookingContext = createContext("BookingContext");

const BookingContextProvider = ({ children }) => {

    const [bookingList, setBookingList] = useState([]);
    const [loadingBookingList, setLoadingBookingList] = useState(false);
    const [BookingListErrorMsg, setBookingListErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getBookingList = async (params) => {

        try {
            setLoadingBookingList(true);
            setBookingListErrorMsg('');
            const response = await BookingService.getBookingList(params);
            setBookingList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingBookingList(false);
        } catch (error) {
            setLoadingBookingList(false);
            const message = error.response ? error.response.data.message : error.message;
            setBookingListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <BookingContext.Provider
            value={{
                getBookingList,
                bookingList,
                loadingBookingList,
                BookingListErrorMsg,
                totalElements
            }}
        >
            {children}
        </BookingContext.Provider>
    );
}

export default BookingContextProvider;