import React, {createContext, useState} from 'react';
import FabricService from "../services/FabricService";
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";

export const FabricContext = createContext("FabricContext");

const FabricContextProvider = ({children}) => {

    const [fabricList, setFabricList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingFabricList, setLoadingFabricList] = useState(false);
    const [fabricListErrorMsg, setFabricListErrorMsg] = useState('');

    const getFabricList = async (params) => {

        try {
            setLoadingFabricList(true);
            setFabricListErrorMsg('');
            const response = await FabricService.getFabricList(params);
            setFabricList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingFabricList(false);
        } catch (error) {
            setLoadingFabricList(false);
            const message = getErrorMessage(error);
            setFabricListErrorMsg(message);
            Toast('error', "Error", message);
        }

    }

    return (
        <FabricContext.Provider
            value={{
                getFabricList,
                fabricList,
                loadingFabricList,
                fabricListErrorMsg,
                totalElements
            }}
        >
            {children}
        </FabricContext.Provider>
    );
}

export default FabricContextProvider;