import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_CITY_URL,
    GET_CITY_BY_ID,
    GET_CITY_LIST,
    POST_CITY_URL,
    UPDATE_CITY_URL
} from "../helpers/Constant";

export default class CityService {

    static getCityList(params) {
        return axios.get(GET_CITY_LIST, { ...CityService.getAuthHeader(), params });
    }

    static addCity(data) {
        return axios.post(POST_CITY_URL, data, CityService.getAuthHeader());
    }

    static getCityById(id) {
        return axios.get(`${GET_CITY_BY_ID}/${id}`, CityService.getAuthHeader());
    }

    static deleteCity(id) {
        return axios.delete(`${DELETE_CITY_URL}/${id}`, CityService.getAuthHeader());
    }

    static editCity(id, data) {
        return axios.put(`${UPDATE_CITY_URL}/${id}`, data, CityService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return { headers: { "x-auth-token": accessToken } };
    }

}