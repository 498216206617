const Permission = {

    ALL: 'ALL',

    READ_USER: 'READ_USER',
    CREATE_USER: 'CREATE_USER',
    MODIFY_USER: 'MODIFY_USER',
    DELETE_USER: 'DELETE_USER',
    READ_USER_PROFILE: 'READ_USER_PROFILE',
    MODIFY_USER_PROFILE: 'MODIFY_USER_PROFILE',

    READ_ROLE: 'READ_ROLE',
    CREATE_ROLE: 'CREATE_ROLE',
    MODIFY_ROLE: 'MODIFY_ROLE',
    DELETE_ROLE: 'DELETE_ROLE',
    READ_PERMISSION: 'READ_PERMISSION',
    UPDATE_USER_PASSWORD: "UPDATE_USER_PASSWORD",
    UPDATE_PROFILE_PASSWORD: "UPDATE_PROFILE_PASSWORD",

    CREATE_DESIGN: "CREATE_DESIGN",
    READ_DESIGN: "READ_DESIGN",
    MODIFY_DESIGN: "MODIFY_DESIGN",
    DELETE_DESIGN: "DELETE_DESIGN",

    CREATE_DESIGN_TYPE: "CREATE_DESIGN_TYPE",
    READ_DESIGN_TYPE: "READ_DESIGN_TYPE",
    MODIFY_DESIGN_TYPE: "MODIFY_DESIGN_TYPE",
    DELETE_DESIGN_TYPE: "DELETE_DESIGN_TYPE",

    CREATE_FABRIC: "CREATE_FABRIC",
    READ_FABRIC: "READ_FABRIC",
    MODIFY_FABRIC: "MODIFY_FABRIC",

    HOMEPAGE_DESIGN_MANAGE: "HOMEPAGE_DESIGN_MANAGE",

    READ_ORDER: "READ_ORDER",

    CREATE_PROMO: "CREATE_PROMO",
    READ_PROMO: "READ_PROMO",
    MODIFY_PROMO: "MODIFY_PROMO",
    DELETE_PROMO: "DELETE_PROMO",

    CREATE_GLOBAL_CONFIGS: 'CREATE_GLOBAL_CONFIGS',
    READ_GLOBAL_CONFIGS: 'READ_GLOBAL_CONFIGS',
    READ_SINGLE_GLOBAL_CONFIG: 'READ_SINGLE_GLOBAL_CONFIG',
    UPDATE_GLOBAL_CONFIGS: 'UPDATE_GLOBAL_CONFIGS',
    DELETE_GLOBAL_CONFIGS: 'DELETE_GLOBAL_CONFIGS',

    CREATE_CITIES: 'CREATE_CITIES',
    READ_CITIES: 'READ_CITIES',
    READ_SINGLE_CITY: 'READ_SINGLE_CITY',
    UPDATE_CITIES: 'UPDATE_CITIES',
    DELETE_CITIES: 'DELETE_CITIES',


    CREATE_COUNTRY_CODES: 'CREATE_COUNTRY_CODES',
    READ_COUNTRY_CODES: 'READ_COUNTRY_CODES',
    READ_SINGLE_COUNTRY_CODE: 'READ_SINGLE_COUNTRY_CODE',
    UPDATE_COUNTRY_CODES: 'UPDATE_COUNTRY_CODES',
    DELETE_COUNTRY_CODES: 'DELETE_COUNTRY_CODES',
}

export default Permission;
