import React, {createContext, useState} from 'react';
import IssueService from "../services/IssueService";
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";

export const IssueContext = createContext("IssueContext");

const IssueContextProvider = ({children}) => {

    const [issueList, setIssueList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingIssueList, setLoadingIssueList] = useState(false);
    const [issueListErrorMsg, setIssueListErrorMsg] = useState('');

    const getIssueList = async (params) => {

        try {
            setLoadingIssueList(true);
            setIssueListErrorMsg('');
            const response = await IssueService.getIssuesList(params);
            setIssueList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingIssueList(false);
        } catch (error) {
            setLoadingIssueList(false);
            const message = getErrorMessage(error);
            setIssueListErrorMsg(message);
            Toast('error', "Error", message);
        }

    }

    return (
        <IssueContext.Provider
            value={{
                getIssueList,
                issueList,
                loadingIssueList,
                issueListErrorMsg,
                totalElements
            }}
        >
            {children}
        </IssueContext.Provider>
    );
}

export default IssueContextProvider;