import React, {createContext, useState} from 'react';
import HomepageDesignService from "../services/HomepageDesignService";
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";

export const HomepageDesignContext = createContext("HomepageDesignContext");

const HomepageDesignContextProvider = ({children}) => {

    const [homepageDesignList, setHomepageDesignList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingHomepageDesignList, setHomepageDesignListLoading] = useState(false);
    const [homepageDesignListErrorMsg, setHomepageDesignListErrorMsg] = useState('');

    const getHomepageDesignList = async (params) => {

        try {
            setHomepageDesignListLoading(true);
            setHomepageDesignListErrorMsg('');
            const response = await HomepageDesignService.getHomepageDesignList(params);
            setHomepageDesignList(response.data.content);
            setTotalElements(response.data.totalElements);
            setHomepageDesignListLoading(false);
        } catch (error) {
            setHomepageDesignListLoading(false);
            const message = getErrorMessage(error);
            setHomepageDesignListErrorMsg(message);
            Toast('error', "Error", message);
        }

    }

    return (
        <HomepageDesignContext.Provider
            value={{
                getHomepageDesignList,
                homepageDesignList,
                loadingHomepageDesignList,
                homepageDesignListErrorMsg,
                totalElements
            }}
        >
            {children}
        </HomepageDesignContext.Provider>
    );
}

export default HomepageDesignContextProvider;