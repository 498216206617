import axios from "axios";
import {
    CREATE_DESIGN_TAG_URL,
    DELETE_DESIGN_TAG_URL,
    GET_DESIGN_TAG_BY_ID,
    GET_DESIGN_TAG_LIST,
    UPDATE_DESIGN_TAG_URL
} from "../helpers/Constant";

export default class DesignTagService {

    static getDesignTagList(params) {
        return axios.get(GET_DESIGN_TAG_LIST, {params});
    }

    static getDesignTagById(id) {
        return axios.get(`${GET_DESIGN_TAG_BY_ID}/${id}`);
    }

    static addDesignTag(data) {
        return axios.post(CREATE_DESIGN_TAG_URL, data);
    }

    static updateDesignTag(data, id) {
        return axios.put(`${UPDATE_DESIGN_TAG_URL}/${id}`, data);
    }

    static deleteDesignTag(id) {
        return axios.delete(`${DELETE_DESIGN_TAG_URL}/${id}`, null);
    }
}