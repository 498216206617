import React, { createContext, useState } from 'react';
import PopupBannersService from "../services/PopupBannersService";
import { Toast } from "../components/common/Toast";

export const PopupBannersContext = createContext("PopupBannersContext");

const PopupBannersContextProvider = ({ children }) => {

    const [popupBannersList, setPopupBannersList] = useState([]);
    const [loadingPopupBannersList, setLoadingPopupBannersList] = useState(false);
    const [PopupBannersListErrorMsg, setPopupBannersListErrorMsg] = useState('');

    const getPopupBannersList = async (params) => {

        try {
            setLoadingPopupBannersList(true);
            setPopupBannersListErrorMsg('');
            const response = await PopupBannersService.getPopupBannersList(params);
            setPopupBannersList(response.data.content);
            setLoadingPopupBannersList(false);
        } catch (error) {
            setLoadingPopupBannersList(false);
            const message = error.response ? error.response.data.message : error.message;
            setPopupBannersListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <PopupBannersContext.Provider
            value={{
                getPopupBannersList,
                popupBannersList,
                loadingPopupBannersList,
                PopupBannersListErrorMsg
            }}
        >
            {children}
        </PopupBannersContext.Provider>
    );
}

export default PopupBannersContextProvider;