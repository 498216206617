import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_POPUP_BANNERS_URL,
    GET_POPUP_BANNERS_BY_ID,
    GET_POPUP_BANNERS_LIST,
    POST_POPUP_BANNERS_URL,
    UPDATE_POPUP_BANNERS_URL
} from "../helpers/Constant";

export default class PopupBannersService {

    static getPopupBannersList(params) {
        return axios.get(GET_POPUP_BANNERS_LIST, { ...PopupBannersService.getAuthHeader(), params });
    }

    static addPopupBanners(data) {
        return axios.post(POST_POPUP_BANNERS_URL, data, PopupBannersService.getAuthHeader());
    }

    static getPopupBannersById(id) {
        return axios.get(`${GET_POPUP_BANNERS_BY_ID}/${id}`, PopupBannersService.getAuthHeader());
    }

    static deletePopupBanners(id) {
        return axios.delete(`${DELETE_POPUP_BANNERS_URL}/${id}`, PopupBannersService.getAuthHeader());
    }

    static editPopupBanners(id, data) {
        return axios.put(`${UPDATE_POPUP_BANNERS_URL}/${id}`, data, PopupBannersService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return { headers: { "x-auth-token": accessToken } };
    }

}