import React, {createContext, useState} from 'react';
import DesignTagService from "../services/DesignTagService";
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";

export const DesignTagContext = createContext("DesignTagContext");

const DesignTagContextProvider = ({children}) => {

    const [designTagList, setDesignTagList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingDesignTagList, setLoadingDesignTagList] = useState(false);
    const [designTagListErrorMsg, setDesignTagListErrorMsg] = useState('');

    const getDesignTagList = async (params) => {

        try {
            setLoadingDesignTagList(true);
            setDesignTagListErrorMsg('');
            const response = await DesignTagService.getDesignTagList(params);
            setDesignTagList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingDesignTagList(false);
        } catch (error) {
            setLoadingDesignTagList(false);
            const message = getErrorMessage(error);
            setDesignTagListErrorMsg(message);
            Toast('error', "Error", message);
        }

    }

    return (
        <DesignTagContext.Provider
            value={{
                getDesignTagList,
                designTagList,
                loadingDesignTagList,
                designTagListErrorMsg,
                totalElements
            }}
        >
            {children}
        </DesignTagContext.Provider>
    );
}

export default DesignTagContextProvider;