export const ROOT_PATH = '/';
export const DASHBOARD_PATH = `${ROOT_PATH}dashboard`;
export const SEND_NOTIFICATION_PATH = `${ROOT_PATH}send-notification`;

export const LOGIN_PATH = `${ROOT_PATH}login`;

//User
export const USER_LIST_VIEW_PATH = `${ROOT_PATH}user-list`;
export const EMPLOYEE_LIST_VIEW_PATH = `${ROOT_PATH}employee-list`;
export const CUSTOMER_LIST_VIEW_PATH = `${ROOT_PATH}customer-list`;
export const PROVIDER_LIST_VIEW_PATH = `${ROOT_PATH}provider-list`;
export const PENDING_PROVIDER_LIST_VIEW_PATH = `${ROOT_PATH}pending-provides-list`;
export const USER_VIEW_PATH = `${ROOT_PATH}user`;
export const ADD_USER_PATH = `${ROOT_PATH}add-user`;
export const EDIT_USER_PATH = `${ROOT_PATH}edit-user`;
export const PROVIDER_PROFILE_EDIT_PATH = `${ROOT_PATH}edit-provider-profile`;
export const USER_PROFILE_PATH = `${ROOT_PATH}user-profile`;

//role
export const ROLE_LIST_VIEW_PATH = `${ROOT_PATH}role-list`;
export const ADD_ROLE_PATH = `${ROOT_PATH}add-role`;
export const EDIT_ROLE_PATH = `${ROOT_PATH}edit-role`;

//design
export const DESIGN_LIST_VIEW_PATH = `${ROOT_PATH}design-list`;
export const ADD_DESIGN_PATH = `${ROOT_PATH}add-design`;
export const EDIT_DESIGN_PATH = `${ROOT_PATH}edit-design`;
export const DESIGN_DETAILS_VIEW_PATH = `${ROOT_PATH}design-details`;

//design type
export const DESIGN_TYPE_LIST_VIEW_PATH = `${ROOT_PATH}design-type-list`;
export const ADD_DESIGN_TYPE_PATH = `${ROOT_PATH}add-design-type`;
export const EDIT_DESIGN_TYPE_PATH = `${ROOT_PATH}edit-design-type`;
export const DESIGN_TYPE_DETAILS_VIEW_PATH = `${ROOT_PATH}design-type-details`;

//design tag
export const DESIGN_TAG_LIST_VIEW_PATH = `${ROOT_PATH}design-tag-list`;
export const ADD_DESIGN_TAG_PATH = `${ROOT_PATH}add-design-tag`;
export const EDIT_DESIGN_TAG_PATH = `${ROOT_PATH}edit-design-tag`;

//fabric
export const FABRIC_LIST_VIEW_PATH = `${ROOT_PATH}fabric-list`;
export const ADD_FABRIC_PATH = `${ROOT_PATH}add-fabric`;
export const EDIT_FABRIC_PATH = `${ROOT_PATH}edit-fabric`;
export const FABRIC_DETAILS_VIEW_PATH = `${ROOT_PATH}fabric-details`;

//homepage-design
export const HOMEPAGE_DESIGN_LIST_VIEW_PATH = `${ROOT_PATH}homepage-design-list`;
export const ADD_HOMEPAGE_DESIGN_PATH = `${ROOT_PATH}add-homepage-design`;
export const EDIT_HOMEPAGE_DESIGN_PATH = `${ROOT_PATH}edit-homepage-design`;
export const HOMEPAGE_DESIGN_DETAILS_VIEW_PATH = `${ROOT_PATH}homepage-details-design`;

//order
export const ORDER_LIST_VIEW_PATH = `${ROOT_PATH}order-list`;
export const ADD_ORDER_PATH = `${ROOT_PATH}add-order`;
export const EDIT_ORDER_PATH = `${ROOT_PATH}edit-order`;
export const ORDER_DETAILS_VIEW_PATH = `${ROOT_PATH}order-details`;

//promo
export const PROMO_LIST_VIEW_PATH = `${ROOT_PATH}promo-list`;
export const ADD_PROMO_PATH = `${ROOT_PATH}add-promo`;
export const EDIT_PROMO_PATH = `${ROOT_PATH}edit-promo`;
export const PROMO_DETAILS_VIEW_PATH = `${ROOT_PATH}promo-details`;

//service
export const SERVICE_LIST_VIEW_PATH = `${ROOT_PATH}service-list`;
export const REQUESTED_SERVICE_LIST_VIEW_PATH = `${ROOT_PATH}requested-service-list`;
export const SERVICE_VIEW_PATH = `${ROOT_PATH}service`;
export const ADD_SERVICE_PATH = `${ROOT_PATH}add-service`;
export const EDIT_SERVICE_PATH = `${ROOT_PATH}edit-service`;

//global_config
export const GLOBAL_CONFIG_LIST_VIEW_PATH = `${ROOT_PATH}global-config-list`;
export const ADD_GLOBAL_CONFIG_PATH = `${ROOT_PATH}add-global-config`;
export const EDIT_GLOBAL_CONFIG_PATH = `${ROOT_PATH}edit-global-config`;

//city
export const CITY_LIST_VIEW_PATH = `${ROOT_PATH}city-list`;
export const ADD_CITY_PATH = `${ROOT_PATH}add-city`;
export const EDIT_CITY_PATH = `${ROOT_PATH}edit-city`;

//country-code
export const COUNTRY_CODE_LIST_VIEW_PATH = `${ROOT_PATH}country-code-list`;
export const ADD_COUNTRY_CODE_PATH = `${ROOT_PATH}add-country-code`;
export const EDIT_COUNTRY_CODE_PATH = `${ROOT_PATH}edit-country-code`;

//employee-salary
export const EMPLOYEE_SALARY_LIST_VIEW_PATH = `${ROOT_PATH}employee-salary-list`;
export const ADD_EMPLOYEE_SALARY_PATH = `${ROOT_PATH}add-employee-salary`;
export const EDIT_EMPLOYEE_SALARY_PATH = `${ROOT_PATH}edit-employee-salary`;

//static_page
export const STATIC_PAGE_LIST_VIEW_PATH = `${ROOT_PATH}static-page-list`;
export const ADD_STATIC_PAGE_PATH = `${ROOT_PATH}add-static-page`;
export const EDIT_STATIC_PAGE_PATH = `${ROOT_PATH}edit-static-page`;

//booking page
export const BOOKING_LIST_VIEW_PATH = `${ROOT_PATH}booking-list`;
export const BOOKING_VIEW_PATH = `${ROOT_PATH}booking`;

//Issue
export const ISSUE_LIST_VIEW_PATH = `${ROOT_PATH}issue-list`;
export const ISSUE_VIEW_PATH = `${ROOT_PATH}issue`;
export const EDIT_ISSUE_PATH = `${ROOT_PATH}edit-issue`;

//transaction
export const TRANSACTION_LIST_VIEW_PATH = `${ROOT_PATH}transaction-list`;
export const ADD_TRANSACTION_PATH = `${ROOT_PATH}add-transaction`;
export const TRANSACTION_DETAILS_VIEW_PATH = `${ROOT_PATH}transaction-details-view`;

//transaction
export const GKASH_TRANSACTION_LIST_VIEW_PATH = `${ROOT_PATH}gkash-transaction-list`;
export const ADD_GKASH_TRANSACTION_PATH = `${ROOT_PATH}add-gkash-transaction`;
export const GKASH_TRANSACTION_DETAILS_VIEW_PATH = `${ROOT_PATH}gkash-transaction-details-view`;

//popup banner
export const POPUP_BANNERS_LIST_VIEW_PATH = `${ROOT_PATH}popup-banners-list`;
export const ADD_POPUP_BANNERS_PATH = `${ROOT_PATH}add-popup-banners`;
export const EDIT_POPUP_BANNERS_PATH = `${ROOT_PATH}edit-popup-banners`;

//withdraw
export const WITHDRAW_LIST_VIEW_PATH = `${ROOT_PATH}withdraw-list`;
export const WITHDRAW_VIEW_PATH = `${ROOT_PATH}withdraw`;

export const PAGE_403_PATH = `${ROOT_PATH}403`;
export const PAGE_404_PATH = `${ROOT_PATH}404`;
export const PAGE_500_PATH = `${ROOT_PATH}500`;
