import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_COUNTRY_CODE_URL,
    GET_COUNTRY_CODE_BY_ID,
    GET_COUNTRY_CODE_LIST,
    POST_COUNTRY_CODE_URL,
    UPDATE_COUNTRY_CODE_URL
} from "../helpers/Constant";

export default class CountryCodeService {

    static getCountryCodeList(params) {
        return axios.get(GET_COUNTRY_CODE_LIST, { ...CountryCodeService.getAuthHeader(), params });
    }

    static addCountryCode(data) {
        return axios.post(POST_COUNTRY_CODE_URL, data, CountryCodeService.getAuthHeader());
    }

    static getCountryCodeById(id) {
        return axios.get(`${GET_COUNTRY_CODE_BY_ID}/${id}`, CountryCodeService.getAuthHeader());
    }

    static deleteCountryCode(id) {
        return axios.delete(`${DELETE_COUNTRY_CODE_URL}/${id}`, CountryCodeService.getAuthHeader());
    }

    static editCountryCode(id, data) {
        return axios.put(`${UPDATE_COUNTRY_CODE_URL}/${id}`, data, CountryCodeService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return { headers: { "x-auth-token": accessToken } };
    }

}