import React, {createContext, useState} from 'react';
import UserService from "../services/UserService";
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";

export const UserContext = createContext("UserContext");

const UserContextProvider = ({children}) => {

    const [userList, setUserList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingUserList, setLoadingUserList] = useState(false);
    const [userListErrorMsg, setUserListErrorMsg] = useState('');

    const getUserList = async (params) => {

        try {
            setLoadingUserList(true);
            setUserListErrorMsg('');
            const response = await UserService.getUsersList(params);
            setUserList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingUserList(false);
        } catch (error) {
            setLoadingUserList(false);
            const message = getErrorMessage(error);
            setUserListErrorMsg(message);
            Toast('error', "Error", message);
        }

    }

    return (
        <UserContext.Provider
            value={{
                getUserList,
                userList,
                loadingUserList,
                userListErrorMsg,
                totalElements
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;