import React, {createContext, useState} from 'react';
import ServiceService from "../services/ServiceService";
import {Toast} from "../components/common/Toast";

export const ServiceContext = createContext("ServiceContext");

const ServiceContextProvider = ({children}) => {

    const [serviceList, setServiceList] = useState([]);
    const [parentServiceList, setParentServiceList] = useState([]);
    const [loadingServiceList, setLoadingServiceList] = useState(false);
    const [loadingParentServiceList, setLoadingParentServiceList] = useState(false);
    const [serviceListErrorMsg, setServiceListErrorMsg] = useState('');
    const [totalElements, setTotalElements] = useState(0);

    const getServiceList = async (params) => {
        try {
            setLoadingServiceList(true);
            setServiceListErrorMsg('');
            const response = await ServiceService.getServiceList(params);
            setTotalElements(response.data.totalElements)
            setServiceList(response.data.content);
            setLoadingServiceList(false);
        } catch (error) {
            setLoadingServiceList(false);
            const message = error.response ? error.response.data.message : error.message;
            setServiceListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    const getParentServiceList = async (params) => {

        try {
            setLoadingParentServiceList(true);
            setServiceListErrorMsg('');
            const response = await ServiceService.getParentServiceList(params);
            setParentServiceList(response.data);
            setLoadingParentServiceList(false);
        } catch (error) {
            setLoadingParentServiceList(false);
            const message = error.response ? error.response.data : error.message;
            Toast("error", "Error", message);
        }

    }

    return (
        <ServiceContext.Provider
            value={{
                getServiceList,
                getParentServiceList,
                serviceList,
                loadingServiceList,
                serviceListErrorMsg,
                parentServiceList,
                loadingParentServiceList,
                totalElements
            }}
        >
            {children}
        </ServiceContext.Provider>
    );
}

export default ServiceContextProvider;