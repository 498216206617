import {useLocation} from 'react-router-dom';
import Permission from "./Permission";
import {ACCESS_TOKEN, BD_TK_SIGN} from "./Constant";
import moment from "moment";
import { Toast } from '../components/common/Toast';

export const getErrorMessage = (error) => {
    if (error.response) {

        if (error.response.data) {
            return error.response.data.message;
        }
        Toast("error", "Error",  error.response.data.message);
        return error.response.data.message;
    } else {
        Toast("error", "Error",  error.message);
        return error.message;
    }
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const getParams = (query, data) => {
    for (const key of Object.keys(data)) {
        data = {...data, [key]: query.get(key) || ""}
    }
    return data;
}

export const isValueExistInSearch = (data) => {

    for (const key of Object.keys(data)) {
        if (data[key]) {
            return ["1"];
        }
    }
    return ["0"];
}

export const getBase64Image = (data) => {
    const base64 = btoa(
        new Uint8Array(data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
        ),
    );

    return "data:;base64," + base64;
}

export const bindUrlWithParams = (url, params) => {
    let result = url;

    Object.keys(params).forEach(key => {
        if (!params[key]) {
            delete params[key];
        }
    });

    Object.keys(params).forEach((key, index) => {

        if (index === 0) {
            result += `?${key}=${params[key]}`;
        } else {
            result += `&${key}=${params[key]}`;
        }

    });

    return result;
}

export const getFullPreviousRoute = (history) => {
    return `${history.location.pathname}${history.location.search}`;
}

export const resetState = (data) => {
    for (const key of Object.keys(data)) {
        data = {...data, [key]: ""}
    }
    return data;
}


export const getAllNonObjectValues = (data) => {

    let singleValue = {};

    for (const [key, value] of Object.entries(data)) {
        if (typeof value !== "object") {
            singleValue[key] = value;
        }
    }
    return singleValue;
}

export const getAllNestedObjectValues = (data) => {
    let singleValue = [];

    for (const value of Object.values(data)) {
        if (value && typeof value === "object") {
            singleValue.push(value);
        }
    }
    return singleValue
}

export const getPercentageValue = (value, percent) => {
    return (value * percent) / 100;
}

export const hasPermission = (loggedInUserPermissions, permissions) => {

    if (!permissions) {
        return false;
    }

    if (permissions.includes(Permission.ALL)) {
        return true;
    }

    if (!loggedInUserPermissions) {
        return false;
    }

    for (const permission of permissions) {

        if (loggedInUserPermissions.includes(permission)) {
            return true;
        }

    }

    return false;
}

export const dateTimeFormatter = date => {
    if (!date) return;
    return moment(date, "DD-MM-YYYY HH:mm:ss").format("D MMM YYYY HH:mm A");
}

export const dateFormatter = date => {
    if (!date) return;
    return moment(date, "DD-MM-YYYY HH:mm:ss").format("D MMM YYYY");
}

export const timeFormatter = date => {
    if (!date) return;
    return moment(date, "DD-MM-YYYY HH:mm:ss").format("HH:mm A");
}

export const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const moneyFormatter = (value) => {
    return `${BD_TK_SIGN} ${formatter.format(value)}`;
}

export const enumFormatter = value => {
    if (!value) return;

    const words = value.toLowerCase().split("_");
    return words.map(value => value[0].toUpperCase() + value.substring(1))
        .join(" ");
}

export const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const showDateFormat = "DD-MM-YYYY";
export const sendDateFormat = "DD-MM-yyyy";

export const showDateTimeFormat = "DD-MM-YYYY HH:mm:ss";
export const sendDateTimeFormat = "DD-MM-yyyy HH:mm:ss";

export const getAuthHeaders = () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    return {"x-auth-token": accessToken};
}

export const booleanTagColor = value => value ? 'green' : 'red';
