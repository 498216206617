import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

// SCSS & CSS
import 'antd/dist/antd.css';
import './assets/scss/style.scss';

import AuthContextProvider from "./contexts/AuthContextProvider";
import UserContextProvider from "./contexts/UserContextProvider";
import RoleContextProvider from "./contexts/RoleContextProvider";
import DesignContextProvider from "./contexts/DesignContextProvider";
import FabricContextProvider from "./contexts/FabricContextProvider";
import DesignTagContextProvider from "./contexts/DesignTagContextProvider";
import OrderContextProvider from "./contexts/OrderContextProvider";
import HomepageDesignContextProvider from "./contexts/HomepageDesignContextProvider";
import GlobalContextProvider from "./contexts/GlobalContextProvider";
import DesignTypeContextProvider from "./contexts/DesignTypeContextProvider";
import PromoContextProvider from "./contexts/PromoContextProvider";
import GlobalConfigContextProvider from './contexts/GlobalConfigContextProvider';
import CityContextProvider from './contexts/CityContextProvider';
import CountryCodeContextProvider from './contexts/CountryCodeContextProvider';
import ServiceContextProvider from './contexts/ServiceContextProvider';
import EmployeeSalaryContextProvider from './contexts/EmployeeSalaryContextProvider';
import StaticPageContextProvider from './contexts/StaticPageContextProvider';
import BookingContextProvider from './contexts/BookingContextProvider';
import IssueContextProvider from './contexts/IssueContextProvider';
import TransactionContextProvider from './contexts/TransactionContextProvider';
import PopupBannersContextProvider from './contexts/PopupBannersContextProvider';
import WithdrawContextProvider from './contexts/WithdrawContextProvider';

const contextProviders = [
    AuthContextProvider,
    UserContextProvider,
    DesignContextProvider,
    DesignTypeContextProvider,
    FabricContextProvider,
    DesignTagContextProvider,
    OrderContextProvider,
    HomepageDesignContextProvider,
    PromoContextProvider,
    GlobalContextProvider,
    GlobalConfigContextProvider,
    CityContextProvider,
    CountryCodeContextProvider,
    ServiceContextProvider,
    RoleContextProvider,
    EmployeeSalaryContextProvider,
    StaticPageContextProvider,
    BookingContextProvider,
    IssueContextProvider,
    TransactionContextProvider,
    PopupBannersContextProvider,
    WithdrawContextProvider
]

const getAppWithContextProviders = () => {

    let result = <App />
    contextProviders.forEach(Provider => result = <Provider>{result}</Provider>);

    return result;
}

ReactDOM.render(
    // <React.StrictMode>
    getAppWithContextProviders(),
    // </React.StrictMode>
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
