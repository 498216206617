import axios from "axios";
import {
    CREATE_DESIGN_URL,
    DELETE_DESIGN_URL,
    GET_DESIGN_BY_ID,
    GET_DESIGN_LIST,
    UPDATE_DESIGN_URL
} from "../helpers/Constant";

export default class DesignService {

    static getDesignList(params) {
        return axios.get(GET_DESIGN_LIST, {params});
    }

    static getDesignById(id) {
        return axios.get(`${GET_DESIGN_BY_ID}/${id}`);
    }

    static addDesign(data) {
        return axios.post(CREATE_DESIGN_URL, data);
    }

    static updateDesign(data, id) {
        return axios.put(`${UPDATE_DESIGN_URL}/${id}`, data);
    }

    static deleteDesign(id) {
        return axios.delete(`${DELETE_DESIGN_URL}/${id}`);
    }
}
