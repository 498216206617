import React, { createContext, useState } from 'react';
import CityService from "../services/CityService";
import { Toast } from "../components/common/Toast";

export const CityContext = createContext("CityContext");

const CityContextProvider = ({ children }) => {

    const [cityList, setCityList] = useState([]);
    const [loadingCityList, setLoadingCityList] = useState(false);
    const [CityListErrorMsg, setCityListErrorMsg] = useState('');

    const getCityList = async (params) => {

        try {
            setLoadingCityList(true);
            setCityListErrorMsg('');
            const response = await CityService.getCityList(params);
            setCityList(response.data);
            setLoadingCityList(false);
        } catch (error) {
            setLoadingCityList(false);
            const message = error.response ? error.response.data.message : error.message;
            setCityListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <CityContext.Provider
            value={{
                getCityList,
                cityList,
                loadingCityList,
                CityListErrorMsg
            }}
        >
            {children}
        </CityContext.Provider>
    );
}

export default CityContextProvider;