import axios from "axios";
import {
    ACCESS_TOKEN, GET_ALL_BOOKINGS, GET_BOOKING_BY_ID,
} from "../helpers/Constant";

export default class BookingService {

    static getBookingList(params) {
        return axios.get(GET_ALL_BOOKINGS, { ...BookingService.getAuthHeader(), params });
    }

    static getBookingById(id) {
        return axios.get(`${GET_BOOKING_BY_ID}/${id}`, BookingService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return { headers: { "x-auth-token": accessToken } };
    }

}