import axios from "axios";
import {CREATE_PROMO_URL, GET_PROMO_BY_ID, GET_PROMO_LIST, UPDATE_PROMO_URL,} from "../helpers/Constant";

export default class PromoService {

    static getPromoList(params) {
        return axios.get(GET_PROMO_LIST, {params});
    }

    static getPromoById(id) {
        return axios.get(`${GET_PROMO_BY_ID}/${id}`);
    }

    static addPromo(data) {
        return axios.post(CREATE_PROMO_URL, data);
    }

    static updatePromo(data, id) {
        return axios.put(`${UPDATE_PROMO_URL}/${id}`, data);
    }
}
