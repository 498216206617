import axios from "axios";
import {
    ACCESS_TOKEN, GET_ALL_WITHDRAWS, GET_WITHDRAW_BY_ID,
} from "../helpers/Constant";

export default class WithdrawService {

    static getWithdrawList(params) {
        return axios.get(GET_ALL_WITHDRAWS, { ...WithdrawService.getAuthHeader(), params });
    }

    static getWithdrawById(id) {
        return axios.get(`${GET_WITHDRAW_BY_ID}/${id}`, WithdrawService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return { headers: { "x-auth-token": accessToken } };
    }

}