import React, { createContext, useState } from 'react';
import CountryCodeService from "../services/CountryCodeService";
import { Toast } from "../components/common/Toast";

export const CountryCodeContext = createContext("CountryCodeContext");

const CountryCodeContextProvider = ({ children }) => {

    const [countryCodeList, setCountryCodeList] = useState([]);
    const [loadingCountryCodeList, setLoadingCountryCodeList] = useState(false);
    const [CountryCodeListErrorMsg, setCountryCodeListErrorMsg] = useState('');

    const getCountryCodeList = async (params) => {

        try {
            setLoadingCountryCodeList(true);
            setCountryCodeListErrorMsg('');
            const response = await CountryCodeService.getCountryCodeList(params);
            setCountryCodeList(response.data);
            setLoadingCountryCodeList(false);
        } catch (error) {
            setLoadingCountryCodeList(false);
            const message = error.response ? error.response.data.message : error.message;
            setCountryCodeListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <CountryCodeContext.Provider
            value={{
                getCountryCodeList,
                countryCodeList,
                loadingCountryCodeList,
                CountryCodeListErrorMsg
            }}
        >
            {children}
        </CountryCodeContext.Provider>
    );
}

export default CountryCodeContextProvider;