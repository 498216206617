import axios from "axios";
import {
    ACCESS_TOKEN,
    DELETE_SERVICE_URL,
    GET_SERVICE_BY_ID,
    GET_SERVICE_LIST,
    GET_PARENT_SERVICE_LIST,
    MAKE_HOT_SERVICE_URL,
    POST_SERVICE_URL,
    UPDATE_SERVICE_URL,
    VERIFY_SERVICE_URL
} from "../helpers/Constant";

export default class ServiceService {

    static getServiceList(params) {
        return axios.get(GET_SERVICE_LIST, {...ServiceService.getAuthHeader(), params});
    }

    static getParentServiceList(params) {
        return axios.get(GET_PARENT_SERVICE_LIST, {...ServiceService.getAuthHeader(), params});
    }

    static addService(data) {
        return axios.post(POST_SERVICE_URL, data, ServiceService.getAuthHeader());
    }

    static editService(id, data) {
        return axios.put(`${UPDATE_SERVICE_URL}/${id}`, data, ServiceService.getAuthHeader());
    }

    static deleteService(id) {
        return axios.delete(`${DELETE_SERVICE_URL}/${id}`, ServiceService.getAuthHeader());
    }

    static verifyService(id) {
        return axios.put(`${VERIFY_SERVICE_URL}/${id}`, null, {
            ...ServiceService.getAuthHeader(),
            params: {approved: true}
        });
    }

    static makeHotService(id, hot) {
        return axios.put(`${MAKE_HOT_SERVICE_URL}/${id}`, null, {
            ...ServiceService.getAuthHeader(),
            params: {hot}
        });
    }

    static getServiceById(id) {
        return axios.get(`${GET_SERVICE_BY_ID}/${id}`, ServiceService.getAuthHeader());
    }

    static getAuthHeader = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN);
        return {headers: {"x-auth-token": `${accessToken}`}};
    }

}