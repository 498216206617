import React, {createContext, useState} from 'react';
import DesignTypeService from "../services/DesignTypeService";
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";

export const DesignTypeContext = createContext("DesignTypeContext");

const DesignTypeContextProvider = ({children}) => {

    const [designTypeList, setDesignTypeList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingDesignTypeList, setLoadingDesignTypeList] = useState(false);
    const [designTypeListErrorMsg, setDesignTypeListErrorMsg] = useState('');

    const getDesignTypeList = async (params) => {

        try {
            setLoadingDesignTypeList(true);
            setDesignTypeListErrorMsg('');
            const response = await DesignTypeService.getDesignTypeList(params);
            setDesignTypeList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingDesignTypeList(false);
        } catch (error) {
            setLoadingDesignTypeList(false);
            const message = getErrorMessage(error);
            setDesignTypeListErrorMsg(message);
            Toast('error', "Error", message);
        }

    }

    return (
        <DesignTypeContext.Provider
            value={{
                getDesignTypeList,
                designTypeList,
                loadingDesignTypeList,
                designTypeListErrorMsg,
                totalElements
            }}
        >
            {children}
        </DesignTypeContext.Provider>
    );
}

export default DesignTypeContextProvider;