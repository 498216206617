import React, { createContext, useState } from 'react';
import EmployeeSalaryService from "../services/EmployeeSalaryService";
import { Toast } from "../components/common/Toast";

export const EmployeeSalaryContext = createContext("EmployeeSalaryContext");

const EmployeeSalaryContextProvider = ({ children }) => {

    const [employeeSalaryList, setEmployeeSalaryList] = useState([]);
    const [loadingEmployeeSalaryList, setLoadingEmployeeSalaryList] = useState(false);
    const [EmployeeSalaryListErrorMsg, setEmployeeSalaryListErrorMsg] = useState('');

    const getEmployeeSalaryList = async (params) => {

        try {
            setLoadingEmployeeSalaryList(true);
            setEmployeeSalaryListErrorMsg('');
            const response = await EmployeeSalaryService.getEmployeeSalaryList(params);
            setEmployeeSalaryList(response.data.content);
            setLoadingEmployeeSalaryList(false);
        } catch (error) {
            setLoadingEmployeeSalaryList(false);
            const message = error.response ? error.response.data.message : error.message;
            setEmployeeSalaryListErrorMsg(message);
            Toast("error", "Error", message);
        }

    }

    return (
        <EmployeeSalaryContext.Provider
            value={{
                getEmployeeSalaryList,
                employeeSalaryList,
                loadingEmployeeSalaryList,
                EmployeeSalaryListErrorMsg
            }}
        >
            {children}
        </EmployeeSalaryContext.Provider>
    );
}

export default EmployeeSalaryContextProvider;